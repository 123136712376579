<template>
    <div
        :id="`pendo-table-${id}`"
        ref="table"
        v-observe-visibility="handleVisibilityChange"
        class="pendo-table"
        :class="[
            {
                'pendo-table--fit': fit,
                'pendo-table--striped': stripe,
                'pendo-table--border': border,
                'pendo-table--borderless': borderless,
                'pendo-table--scrollable-x': overflowX,
                'pendo-table--scrollable-y': overflowY,
                'pendo-table--page-mode-scroll': scrollStore.pageMode,
                'pendo-table--highlight-hover': highlightHoverRow,
                'pendo-table--condensed': condensed,
                'pendo-table--title': showTableTitle,
                'pendo-table--filters': !!$slots.filters || !!$scopedSlots.filters,
                'pendo-table--collapsible': collapsible,
                'pendo-table--draggable': draggable,
                'is-underflowing': underflowX,
                'is-collapsed': collapsed,
                'is-loading': loading,
                [`is-${status}`]: Boolean(status)
            }
        ]"
        :style="tableStyle">
        <table-title
            v-if="showTableTitle"
            ref="title">
            <template #headerPrefix>
                <slot name="headerPrefix" />
            </template>
            <template #header>
                <slot name="header" />
            </template>
            <template #headerLeft>
                <slot name="headerLeft" />
            </template>
            <template #headerRight>
                <slot name="headerRight" />
            </template>
            <template #headerActions>
                <slot name="headerActions" />
            </template>
        </table-title>
        <pendo-collapse-transition>
            <div
                v-show="!collapsed"
                :id="`pendo-table__content-${id}`"
                class="pendo-table__content"
                :role="hasNestedRows ? 'treegrid' : 'grid'"
                :aria-rowcount="tableData.length + 1"
                :aria-colcount="ariaColumnCount"
                :aria-labelledby="tableTitleId">
                <div
                    v-if="!!$slots.filters || !!$scopedSlots.filters"
                    ref="filters"
                    class="pendo-table__filters"
                    :style="scrollStore.pageMode && { top: `${scrollStore.stickyElementPositions.filters}px` }">
                    <slot name="filters" />
                </div>
                <div
                    v-if="!!$slots.status || !!$scopedSlots.status"
                    class="pendo-table__status-container">
                    <slot name="status" />
                </div>
                <div
                    v-pendo-loading:material="{
                        loading,
                        text: loadingText,
                        spinnerProps: {
                            color: '#3a3c45',
                            background: '#FFFFFF',
                            size: 48
                        }
                    }">
                    <table-header ref="tableHeader" />
                    <table-body ref="tableBody">
                        <template #append>
                            <slot name="append" />
                        </template>
                        <template #empty>
                            <slot name="empty" />
                        </template>
                    </table-body>
                </div>
            </div>
        </pendo-collapse-transition>
        <div
            v-if="resizable"
            ref="resizeBar"
            class="pendo-table__resize-bar"
            :style="overflowX ? { paddingBottom: `${horizontalScrollbarHeight}px` } : {}" />
        <pendo-column-chooser
            v-if="manageColumns"
            value-key="columnKey"
            disable-first
            :visible.sync="manageColumnStore.visible"
            :columns="manageColumnStore.columns"
            :available-columns="manageColumnStore.availableColumns"
            :placeholder="manageColumnsConfig.placeholder"
            :title="manageColumnsConfig.title"
            :description="manageColumnsConfig.description"
            :add-button-label="manageColumnsConfig.addButtonLabel"
            :confirm-button-config="manageColumnsConfig.confirmButtonConfig"
            :cancel-button-config="manageColumnsConfig.cancelButtonConfig"
            :all-selected-text="manageColumnsConfig.allSelectedText"
            @confirm="hideManageColumnsModal" />
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import scrollParent from 'scrollparent';

import { ObserveVisibility } from 'vue-observe-visibility';
import ResizeObserver from 'resize-observer-polyfill';

import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import { PendoLoading } from '@/directives/loading/pendo-loading';
import PendoCollapseTransition from '@/utils/pendo-collapse-transition';

import TableTitle from '@/components/table/table-title';
import TableHeader from '@/components/table/table-header';
import TableBody from '@/components/table/table-body';
import PendoColumnChooser from '@/components/table/column-chooser/pendo-column-chooser';
import { getCSVFilename, getCSVColumns, tableToCSV, downloadCSV } from '@/components/table/utils/csv';
import { emitEvent, getColumnConfig, flattenChildRows, findRowLocation } from '@/components/table/utils/utils';
import { hasSlot, getShallowObjectDiff } from '@/utils/utils';
import { addClass, removeClass, getScrollParent, getScrollbarWidth, elementPositionInAncestor } from '@/utils/dom';
import { warn } from '@/utils/console';
import { sortRows } from '@/components/table/utils/sort';
import { filterRows } from '@/components/table/utils/filter';
import { groupRows } from '@/components/table/utils/group';

export default {
    name: 'PendoTable',
    components: {
        PendoCollapseTransition,
        PendoColumnChooser,
        TableTitle,
        TableHeader,
        TableBody
    },
    directives: {
        ObserveVisibility,
        PendoLoading,
        PendoTooltip
    },
    provide () {
        return {
            $table: this
        };
    },
    inheritAttrs: false,
    props: {
        /**
         * Table columns
         */
        columns: {
            type: Array,
            default: () => []
        },
        /**
         * Table data
         */
        data: {
            type: Array,
            default: () => []
        },
        /**
         * Height of the table
         */
        height: {
            type: [Number, String],
            default: null
        },
        /**
         * Maximum height of the table
         */
        maxHeight: {
            type: [Number, String],
            default: null
        },
        /**
         * Whether to allow dragging the column width to resize
         */
        resizable: {
            type: Boolean,
            default: false
        },
        /**
         * Whether to allow dragging the tables rows to reorder.
         * Table does not sort when this is true
         */
        draggable: {
            type: Boolean,
            default: false
        },
        /**
         * Whether with zebra pattern
         */
        stripe: {
            type: Boolean,
            default: false
        },
        /**
         * Whether with a vertical border
         */
        border: {
            type: Boolean,
            default: false
        },
        /**
         * Reduces row height to 40px
         */
        condensed: {
            type: Boolean,
            default: false
        },
        /**
         * fit column widths to 100% of container
         */
        fit: {
            type: Boolean,
            default: true
        },
        /**
         * toggles loading indicator visibility
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * optional text to place below the loading indicator. Used in cases where the loading indicator needs additional contextual information.
         */
        loadingText: {
            type: String,
            default: null
        },
        /**
         * optional status of `loading`, `rejected`, `resolved`, or `empty` to help with `status` slot styling
         */
        status: {
            type: String,
            default: null
        },
        /**
         * highlights current selected row
         */
        highlightCurrentRow: {
            type: Boolean,
            default: false
        },
        /**
         * highlight row hover
         */
        highlightHoverRow: {
            type: Boolean,
            default: true
        },
        /**
         * function that returns custom class names for a row, or a string assigning class names for every row
         */
        rowClassName: {
            type: [String, Function],
            default: null
        },
        /**
         * function that returns custom class names for a cell, or a string assigning class names for every cell
         */
        cellClassName: {
            type: [String, Function],
            default: null
        },
        /**
         * function that returns custom class names for a row in table header, or a string assigning class names for every row in table header
         */
        headerRowClassName: {
            type: [String, Function],
            default: null
        },
        /**
         * function that returns custom class names for a cell in table header, or a string assigning class names for every cell in table header
         */
        headerCellClassName: {
            type: [String, Function],
            default: null
        },
        /**
         *  Display ellipsis when content is too large to fit in cell
         */
        showAllOverflow: {
            type: [Boolean, String],
            default: null
        },
        /**
         * key that references a unique value on each row
         */
        rowKey: {
            type: [String, Number],
            default: 'id'
        },
        /**
         * whether to auto height table maxHeight based on viewport
         */
        autoHeight: {
            type: Boolean,
            default: false
        },
        /**
         * if `autoHeight` is true, this represents the offset from bottom of viewport
         */
        autoHeightOffset: {
            type: Number,
            default: 82
        },
        /**
         * Tree structure configuration
         */
        treeConfig: {
            type: Object,
            default: null
        },
        /**
         * group by configuration
         */
        groupConfig: {
            type: Object,
            default: null
        },
        /**
         * Checkbox select configuration
         */
        checkboxConfig: {
            type: Object,
            default: () => ({})
        },
        /**
         * Displayed text when data is empty. You can customize this area using the empty slot if needed
         */
        emptyText: {
            type: String,
            default: 'No Data Found.'
        },
        /**
         * set the default sort column and order. `prop` is used to set default sort column, property order is used to set default sort order
         */
        defaultSort: {
            type: Object,
            default: null
        },
        /**
         * filters all table data
         */
        filters: {
            type: [Object, Array],
            default: null
        },
        /**
         * title of the table. appears above the table itself
         */
        title: {
            type: String,
            default: null
        },
        /**
         * removes outside border from table. used to support old angular style tables
         */
        borderless: {
            type: Boolean,
            default: false
        },
        /**
         * enable csv download. if function is specified, supplied function will be called with table columns and data
         */
        csvDownload: {
            type: [Boolean, Function],
            default: false
        },
        /**
         * specific filename for csv. defaults to tile prop + current timestamp
         */
        csvFilename: {
            type: String,
            default: null
        },
        /**
         * specific filename for csv. defaults to tile prop + current timestamp
         */
        csvConfig: {
            type: Object,
            default: null
        },
        /**
         * adds collapse behavior to table header
         */
        collapsible: {
            type: Boolean,
            default: false
        },
        /**
         * allow user to reorder/change visible columns
         */
        manageColumns: {
            type: Boolean,
            default: false
        },
        /**
         * @ignore
         */
        scrollConfig: {
            type: Object,
            default () {
                return {
                    enabled: true,
                    rowHeight: this.condensed ? 40 : 56,
                    buffer: 300,
                    dynamicRowHeight: false
                };
            }
        },
        /**
         * @ignore
         */
        headerProps: {
            type: Object,
            default: null
        },
        /**
         * @ignore
         */
        tableProps: {
            type: Object,
            default: null
        },
        /**
         * allow custom configuration for the column manager
         */
        manageColumnsConfig: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            activeRow: null,
            allTableColumns: [],
            ariaColumnCount: null,
            bodyStyle: {},
            bodyWidth: 0,
            collapsed: false,
            collapseTooltipContent: 'Collapse',
            filterStore: {
                column: null,
                isAllSelected: false,
                isIndeterminate: false,
                multiple: false,
                options: [],
                visible: false
            },
            groupExpandedMap: {},
            hasNestedRows: false,
            horizontalScrollbarHeight: 0,
            hoverColumnHeader: null,
            hoverRow: null,
            id: this._uid,
            isAllSelected: false,
            isAllTreeExpanded: false,
            isIndeterminate: false,
            manageColumnStore: {
                visible: false
            },
            overflowX: false,
            underflowX: false,
            overflowY: false,
            ready: false,
            scrollLeftToRight: false,
            scrollRightToLeft: false,
            scrollStore: {
                buffer: 300,
                dynamicRowHeight: false,
                enabled: true,
                endIndex: 0,
                position: 0,
                rowHeight: 56,
                scrollActive: false,
                scrollHeight: 0,
                scrollLeft: 0,
                scrollTop: 0,
                startIndex: 0,
                pageMode: false,
                stickyElementPositions: {
                    title: 0,
                    header: 0,
                    filters: 0
                }
            },
            selectedMap: {},
            selectRow: null,
            sortStore: {
                activeSort: null,
                column: null
            },
            tableColumns: [],
            tableData: [],
            tableDataHeightMap: [],
            tableRows: [],
            tableStyle: {},
            treeExpandedMap: {},
            treeExpandedChildMap: {},
            verticalScrollbarWidth: 0
        };
    },
    computed: {
        showTableTitle () {
            return Boolean(
                this.tableTitle ||
                    hasSlot(this, 'headerPrefix') ||
                    hasSlot(this, 'header') ||
                    hasSlot(this, 'headerLeft') ||
                    hasSlot(this, 'headerRight') ||
                    hasSlot(this, 'headerActions')
            );
        },
        tableTitle () {
            if (this.headerProps && this.headerProps.title) {
                return this.headerProps.title;
            }

            return this.title;
        },
        tableTitleId () {
            return `pendo-table__title-${this.id}`;
        },
        tableHeight () {
            if (this.tableProps && this.tableProps.height) {
                return this.tableProps.height;
            }

            return this.height;
        },
        tableMaxHeight () {
            if (this.tableProps && this.tableProps.maxHeight) {
                return this.tableProps.maxHeight;
            }

            return this.maxHeight;
        },
        tableDefaultSort () {
            if (this.tableProps && this.tableProps.defaultSort) {
                return this.tableProps.defaultSort;
            }

            return this.defaultSort;
        },
        tableAutoHeight () {
            if (this.tableProps && this.tableProps.autoHeight) {
                return this.tableProps.autoHeight;
            }

            return this.autoHeight;
        },
        tableAutoHeightOffset () {
            if (this.tableProps && this.tableProps.autoHeightOffset) {
                return this.tableProps.autoHeightOffset;
            }

            return this.autoHeightOffset || 0;
        },
        hasFixedColumns () {
            return this.tableColumns.some((column) => column.fixed);
        },
        hasDynamicRowHeight () {
            return !this.scrollStore.rowHeight || this.scrollStore.dynamicRowHeight;
        }
    },
    watch: {
        condensed () {
            this.scrollStore.rowHeight = this.condensed ? 40 : 56;
            this.updateScrollHeight();
        },
        data (value) {
            this.reloadData(value || [], false);
        },
        filters: {
            handler () {
                this.clearSelected();
                this.updateActiveTableData();
            },
            deep: true
        },
        columns (newValue, oldValue) {
            if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                return;
            }

            this.reloadColumns(newValue);
        },
        groupConfig: {
            handler (newValue, oldValue) {
                const changedKeys = Object.keys(getShallowObjectDiff(oldValue, newValue));

                if (changedKeys.includes('enabled') || changedKeys.includes('groupKey')) {
                    this.reloadData(this.data);
                }
            },
            deep: true
        },
        tableDefaultSort (newValue, oldValue) {
            if (isEqual(newValue, oldValue)) {
                return;
            }

            this.handleDefaultSort();
        },
        status: 'updateTableHeight'
    },
    created () {
        Object.assign(this.scrollStore, this.scrollConfig, {
            startIndex: 0,
            scrollHeight: 0
        });

        this.rowsMap = new Map();
        this.columnsMap = new Map();
        this.tableRows = [];

        this.loadColumns(this.columns);
        this.loadData(this.data);

        if (this.tableDefaultSort) {
            this.handleDefaultSort();
        }

        this.resizeObserver = new ResizeObserver(this.handleResizeEvent);
    },
    async mounted () {
        this.refreshLayout();

        await this.$nextTick();
        this.ready = true;
        this.addResizeObservers();
        this.calculateStickyElementPositions();
    },
    beforeDestroy () {
        this.hideFilterPanel();
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        calculateStickyElementPositions () {
            if (!this.scrollStore.pageMode) {
                return;
            }

            // if .pendo-filter-bar is present, start calculating from the bottom of that element
            const filterBar = document.querySelector('.pendo-filter-bar');
            let offset = 0;
            if (filterBar) {
                const filterBarPosInScrollTarget = elementPositionInAncestor(filterBar, scrollParent(this.$el));
                const filterBarContentRect = filterBar.getBoundingClientRect();
                const filterBarScrollContainerOffset = filterBarContentRect.top - filterBarPosInScrollTarget.top;
                offset += filterBar.offsetHeight + filterBarScrollContainerOffset;
            }

            this.scrollStore.stickyElementPositions.title = offset;
            const title = this.$el.querySelector('.pendo-table__title');
            if (title) {
                offset += title.offsetHeight;
            }

            this.scrollStore.stickyElementPositions.filters = offset;
            const filters = this.$el.querySelector('.pendo-table__filters');
            if (filters) {
                offset += filters.offsetHeight;
            }

            this.scrollStore.stickyElementPositions.header = offset;
        },
        addResizeObservers () {
            if (this.$el) {
                this.resizeObserver.observe(this.$el);
            }

            if (this.tableAutoHeight) {
                const scrollParent = getScrollParent(this.$el);

                if (scrollParent) {
                    this.resizeObserver.observe(scrollParent);
                }
            }
        },
        clearFilter () {
            this.filterStore = {
                isAllSelected: false,
                isIndeterminate: false,
                options: [],
                column: null,
                multiple: false,
                visible: false
            };

            return this.$nextTick();
        },
        clearSelected () {
            this.isAllSelected = false;
            this.isIndeterminate = false;
            this.selectedMap = {};
            emitEvent(this, 'select-clear', [{ selectedRows: [] }]);

            return this.$nextTick();
        },
        clearCurrentRow () {
            this.selectRow = null;
            this.hoverRow = null;
            this.hoverColumnHeader = null;

            return this.$nextTick();
        },
        clearScroll () {
            this.scrollStore = {
                ...this.scrollStore,
                startIndex: 0,
                scrollTop: 0
            };

            const { tableBody } = this.$refs;
            if (tableBody && tableBody.$el) {
                tableBody.$el.scrollTop = 0;
                tableBody.$el.scrollLeft = 0;
            }

            return this.$nextTick();
        },
        clearGroupExpand () {
            this.groupExpandedMap = {};

            return this.$nextTick();
        },
        clearTreeExpand () {
            this.treeExpandedMap = {};
            this.treeExpandedChildMap = {};
            this.isAllTreeExpanded = false;

            return this.$nextTick();
        },
        clearAll (clearScroll) {
            this.clearFilter();

            if (clearScroll) {
                this.clearScroll();
            }

            if (!this.checkboxConfig.reserve) {
                this.clearSelected();
            }

            this.clearTreeExpand();
            this.clearGroupExpand();
        },
        loadColumns (columns) {
            if (!columns) {
                return this.$nextTick();
            }

            const tableColumns = cloneDeep(columns).map((column) => getColumnConfig(column));

            // always place "floating" actions column last in columns array
            const actionsColumn = tableColumns.findIndex((column) => column.type === 'actions');
            if (actionsColumn !== -1) {
                tableColumns.push(...tableColumns.splice(actionsColumn, 1));
            }

            // for backwards compatibility, only filter non-visible columns if
            // consumer is opting into new internal handling of column visibility
            if (this.manageColumns) {
                this.allTableColumns = tableColumns;
                this.tableColumns = tableColumns.filter((column) => column && column.visible);
            } else {
                this.tableColumns = tableColumns;
            }

            this.ariaColumnCount = tableColumns.filter((column) => column && column.type !== 'actions').length;

            this.columnsMap.clear();
            let key;
            this.tableColumns.forEach((column) => {
                key = column.columnKey;
                this.columnsMap.set(key, column);
            });

            return this.$nextTick();
        },
        async loadData (data) {
            if (!data) {
                return this.$nextTick();
            }

            let base = data;

            const isRowGroupingEnabled = get(this.groupConfig, 'enabled', false);
            if (isRowGroupingEnabled) {
                // only clone original dataset when grouping rows
                // cloning the dataset for non-grouped tables will break downstream
                // apps if those apps are mutating the rows directly - see APP-67494
                base = cloneDeep(data);
                const options = {
                    childKey: 'children',
                    sortChildren: this.groupConfig.sortChildren,
                    rowKey: this.rowKey,
                    groupKey: this.groupConfig.groupKey,
                    groupRowHeight: this.groupConfig.groupRowHeight,
                    // always include child rows when building the base dataset
                    childRowFilter: () => true
                };

                // group the rows just as tree data would be passed in
                base = groupRows({ rows: base, options });
            }

            const { rowKey } = this;
            this.rowsMap.clear();
            base.forEach((row) => {
                if (!row[rowKey]) {
                    warn(
                        `One or more rows does not have the supplied 'rowKey' prop of '${rowKey}'. Each row must have a unique '${rowKey}' key. If the row does not have that key, consider changing the 'rowKey' prop to another unique key the row does have.`,
                        this
                    );

                    return;
                }

                this.rowsMap.set(row[rowKey], row);
            });

            this.setGroupExpandedRows();
            await this.updateActiveTableData();

            return this.$nextTick();
        },
        async reloadColumns (columns) {
            this.clearAll();

            await this.loadColumns(columns);

            if (this.sortStore.column) {
                const sortedColumn = this.tableColumns.find(
                    (column) => column.property === this.sortStore.column.property
                );
                if (sortedColumn) {
                    this.setActiveSort(this.sortStore.column.property, this.sortStore.activeSort);
                } else if (this.tableDefaultSort) {
                    this.handleDefaultSort();
                }
            }

            if (!this.sortStore.activeSort && this.tableDefaultSort) {
                this.handleDefaultSort();
            }

            this.updateActiveTableData();
            this.refreshLayout();
        },
        async reloadData (data, clearScroll) {
            this.clearAll(clearScroll);

            await this.loadData(data);

            if (this.checkboxConfig.reserve && this.getSelectedRows().length) {
                this.updateSelectedRows(this.getSelectedRows());
            }
        },
        updateSelectionStatus () {
            const selectedRows = this.getSelectedRows();

            if (!selectedRows.length) {
                this.isAllSelected = false;
                this.isIndeterminate = false;

                return;
            }

            const totalSelectableRows = this.tableData.filter((row) => {
                if (this.checkboxConfig.checkMethod) {
                    return this.checkboxConfig.checkMethod(row);
                }

                return true;
            });

            this.isAllSelected = selectedRows.length === totalSelectableRows.length;

            this.isIndeterminate =
                !this.isAllSelected && this.tableData.some((row) => this.selectedMap[row[this.rowKey]]);
        },
        updateActiveTableData () {
            const { rowKey, tableColumns, rowsMap, filters, sortStore, treeConfig, groupConfig } = this;
            let tableData = [...rowsMap.values()];

            const options = {};
            const isRowGroupingEnabled = groupConfig && groupConfig.enabled;
            const isRowTreeEnabled = treeConfig && treeConfig.children;

            if (isRowGroupingEnabled) {
                // leverage tree filtering/sorting when grouping rows
                options.childKey = 'children';
                options.sortChildren = groupConfig.sortChildren;
                options.rowKey = rowKey;
                options.groupKey = groupConfig.groupKey;
                options.groupRowHeight = groupConfig.groupRowHeight;
                options.childRowFilter = (row) => {
                    if (groupConfig.expandable) {
                        return this.groupExpandedMap[row[rowKey]];
                    }

                    return true;
                };
            }

            if (isRowTreeEnabled) {
                options.childKey = treeConfig.children;
                options.sortChildren = treeConfig.sortChildren;
                options.childRowFilter = (row) => this.treeExpandedMap[row[rowKey]];
            }

            tableData = filterRows({
                rows: tableData,
                columns: tableColumns,
                globalFilters: filters || [],
                options
            });

            if (!this.draggable) {
                tableData = sortRows({
                    rows: tableData,
                    sortConfig: sortStore,
                    options
                });
            }

            if (isRowTreeEnabled || isRowGroupingEnabled) {
                this.hasNestedRows = true;
                tableData = flattenChildRows(tableData, options);
            } else {
                this.hasNestedRows = false;
            }
            if (this.hasDynamicRowHeight) {
                const tableDataHeightMap = [];
                // Pre-compute total height at each node so we don't have to do it on scroll events
                let cummulativeHeight = 0;
                tableData.forEach((row) => {
                    tableDataHeightMap.push(cummulativeHeight);
                    cummulativeHeight += row.height || this.scrollStore.rowHeight;
                });

                this.tableDataHeightMap = tableDataHeightMap;
            }

            this.tableData = tableData;
            emitEvent(this, 'table-data-change', [{ data: tableData }]);

            this.updateScrollHeight();
        },
        updateSelectedRows (selectedRows) {
            const { rowsMap, rowKey } = this;
            if (Array.isArray(selectedRows) && selectedRows.length) {
                const activeRows = selectedRows.filter((selected) => rowsMap.has(selected[rowKey]));
                this.selectedMap = keyBy(activeRows, rowKey);
            } else {
                this.selectedMap = {};
            }

            this.updateSelectionStatus();
        },
        getSelectedRows () {
            return Object.values(this.selectedMap);
        },
        getTreeExpandedRows () {
            return Object.values(this.treeExpandedMap);
        },
        downloadTableData () {
            const columns = getCSVColumns(this);
            const filename = getCSVFilename(this);
            const isRowGroupingEnabled = this.groupConfig && this.groupConfig.enabled;
            const isRowTreeEnabled = this.treeConfig && this.treeConfig.children;
            let data = this.tableData;

            if (isRowGroupingEnabled) {
                data = data.filter((row) => !row.isGroup);
            }

            if (isRowTreeEnabled) {
                data = flattenChildRows(data, {
                    childKey: this.treeConfig.children,
                    childRowFilter: (row) => {
                        return !this.treeExpandedMap[row[this.rowKey]];
                    }
                });
            }

            if (typeof this.csvDownload === 'function') {
                return this.csvDownload(columns, data);
            }

            const header = columns.map((column) => column.label).join(',');
            const csvData = `\ufeff${header}\n${tableToCSV(columns, data)}`;

            return downloadCSV({ csvData, filename });
        },
        handleVisibilityChange (isVisible) {
            if (this.ready && isVisible) {
                requestAnimationFrame(() => {
                    this.refreshLayout();
                });
            }
        },
        handleResizeEvent (entries) {
            entries.forEach((entry) => emitEvent(this, 'resize', [entry]));
            this.refreshLayout();
            this.calculateStickyElementPositions();
        },
        refreshLayout () {
            this.updateColumnsWidth();

            if (!this.collapsed) {
                this.updateTableHeight();
            }

            this.updateScrollHeight();
        },
        /**
         * @public
         */
        scrollToIndex (index) {
            let scroll;
            if (this.hasDynamicRowHeight) {
                scroll = index > 0 ? this.tableDataHeightMap[index] : 0;
            } else {
                scroll = index * this.scrollStore.rowHeight;
            }
            this.scrollToPosition(scroll);
        },
        /**
         * @public
         */
        scrollToPosition (position) {
            const { tableBody } = this.$refs;
            tableBody.$el.scrollTop = position;
            tableBody.handleScroll({
                target: {
                    scrollTop: position
                }
            });
        },
        async updateScrollHeight () {
            const scrollHeight = this.tableData.reduce((acc, row) => {
                acc += row.height || this.scrollStore.rowHeight;

                return acc;
            }, 0);

            if (scrollHeight !== this.scrollStore.scrollHeight) {
                this.scrollStore.scrollHeight = scrollHeight;
                emitEvent(this, 'scroll-height-change', [{ scrollHeight }]);
            }

            const { tableBody } = this.$refs;
            if (!tableBody || !tableBody.$el) {
                return;
            }

            const overflowY = scrollHeight > tableBody.$el.clientHeight;
            if (overflowY !== this.overflowY) {
                this.overflowY = overflowY;
                emitEvent(this, 'overflow-y-change', [{ overflowY }]);

                await this.$nextTick();
                this.updateColumnsWidth();
            }

            return this.$nextTick();
        },
        updateColumnsWidth () {
            const { fit, columnsMap } = this;
            const { tableBody } = this.$refs;
            if (!tableBody || !tableBody.$el) {
                return;
            }

            const bodyElem = tableBody.$el;
            const bodyWidth = bodyElem.getBoundingClientRect().width;
            let totalColumnsWidth = 0;
            let flexColumnsWidth = 0;
            let remainingWidth = bodyWidth;
            const cellMinWidth = 36;
            const fixedWidthColumns = [];
            const fixedRightColumns = [];
            const fixedLeftColumns = [];
            const flexWidthColumns = [];

            columnsMap.forEach((column, key) => {
                switch (column.widthType) {
                    case 'fixed': {
                        const width = parseInt(column.width, 10);
                        totalColumnsWidth += width;

                        column.renderWidth = width;
                        fixedWidthColumns.push(column);

                        break;
                    }
                    case 'flex': {
                        const minWidth = parseInt(column.minWidth, 10);
                        flexColumnsWidth += minWidth;
                        totalColumnsWidth += minWidth;

                        column.renderWidth = minWidth;
                        flexWidthColumns.push(column);

                        break;
                    }
                    case 'resize': {
                        const resizeWidth = parseInt(column.resizeWidth, 10);
                        totalColumnsWidth += resizeWidth;

                        column.renderWidth = resizeWidth;
                        fixedWidthColumns.push(column);

                        break;
                    }
                }

                switch (column.fixed) {
                    case 'left':
                        fixedLeftColumns.push(column);
                        break;
                    case 'right':
                        fixedRightColumns.push(column);
                        break;
                }

                columnsMap.set(key, column);
            });

            remainingWidth -= totalColumnsWidth;

            const verticalScrollbarWidth = this.overflowY ? getScrollbarWidth() : 0;
            this.verticalScrollbarWidth = verticalScrollbarWidth;

            if (flexWidthColumns.length > 0 && fit) {
                if (remainingWidth > verticalScrollbarWidth) {
                    // reset overflow state
                    this.overflowX = false;
                    this.scrollLeftToRight = false;
                    this.scrollRightToLeft = false;

                    const totalFlexWidth = remainingWidth - verticalScrollbarWidth;

                    if (flexWidthColumns.length === 1) {
                        const column = flexWidthColumns[0];
                        column.renderWidth = (column.minWidth || cellMinWidth) + totalFlexWidth;
                        columnsMap.set(column.columnKey, column);
                    } else {
                        const flexWidthPerPixel = totalFlexWidth / flexColumnsWidth;
                        let noneFirstWidth = 0;

                        flexWidthColumns.forEach((column, index) => {
                            if (index === 0) {
                                return;
                            }
                            const flexWidth = Math.floor((column.minWidth || cellMinWidth) * flexWidthPerPixel);
                            noneFirstWidth += flexWidth;

                            column.renderWidth = (column.minWidth || cellMinWidth) + flexWidth;
                            columnsMap.set(column.columnKey, column);
                        });

                        const column = flexWidthColumns[0];
                        column.renderWidth = (column.minWidth || cellMinWidth) + totalFlexWidth - noneFirstWidth;
                        columnsMap.set(column.columnKey, column);
                    }
                } else {
                    this.overflowX = true;
                    flexWidthColumns.forEach((column) => {
                        column.renderWidth = column.minWidth;
                        columnsMap.set(column.columnKey, column);
                    });
                }

                this.bodyWidth = Math.max(totalColumnsWidth, bodyWidth - verticalScrollbarWidth);
            } else {
                this.overflowX = totalColumnsWidth > bodyWidth;
                this.bodyWidth = totalColumnsWidth;
            }

            if (this.overflowX) {
                this.underflowX = false;
                this.scrollLeftToRight = bodyElem.scrollLeft > 0;
                this.scrollRightToLeft = bodyElem.clientWidth < bodyElem.scrollWidth - bodyElem.scrollLeft;
            } else {
                let totalColWidth = 0;
                columnsMap.forEach((column) => {
                    totalColWidth += column.renderWidth;
                });
                this.underflowX = totalColWidth < bodyWidth;
                this.scrollStore.scrollLeft = 0;
            }

            // these calculations need to happen AFTER flex widths have been determined
            // calculate left/right pos of column based on columns that come before or after it
            if (fixedLeftColumns.length > 0) {
                fixedLeftColumns.reduce((fixedPositionLeft, column, index) => {
                    column.disableReorder = true;

                    if (index === fixedLeftColumns.length - 1) {
                        column.fixedShadow = true;
                    }

                    column.fixedPos = fixedPositionLeft;
                    columnsMap.set(column.columnKey, column);

                    return fixedPositionLeft + column.renderWidth;
                }, 0);
            }

            if (fixedRightColumns.length > 0) {
                fixedRightColumns.reduceRight((fixedPositionRight, column, index) => {
                    if (index === fixedRightColumns.length - 1) {
                        return fixedPositionRight + column.renderWidth;
                    }

                    if (index === 0) {
                        column.fixedShadow = true;
                    }

                    column.fixedPos = fixedPositionRight;
                    columnsMap.set(column.columnKey, column);

                    return fixedPositionRight + column.renderWidth;
                }, 0);
            }

            // return early if columns didn't change
            const updatedColumns = [...columnsMap.values()];
            if (isEqual(this.tableColumns, updatedColumns)) {
                return this.$nextTick();
            }

            this.tableColumns = updatedColumns;

            return this.$nextTick();
        },
        showManageColumnsModal () {
            const usedIndices = [];
            const columns = [];
            this.allTableColumns.forEach((column, index) => {
                if (column.allowReorder && column.visible) {
                    usedIndices.push(index);
                    columns.push(column);
                }
            });

            const availableColumns = this.allTableColumns.filter((column) => column.allowReorder);

            this.manageColumnStore = {
                columns,
                availableColumns,
                usedIndices,
                visible: true
            };
        },
        hideManageColumnsModal (columns) {
            const { usedIndices } = this.manageColumnStore;
            const allTableColumns = cloneDeep(this.allTableColumns);

            // reset all column visibility
            allTableColumns.forEach((column) => {
                if (column.allowReorder && column.visible) {
                    column.visible = false;
                }
            });

            columns.forEach((column, index) => {
                const newIndex = usedIndices[index];
                const oldIndex = allTableColumns.findIndex((col) => col.property === column.property);
                allTableColumns[oldIndex].visible = true;

                // adding new column
                if (newIndex == null) {
                    allTableColumns.push(...allTableColumns.splice(oldIndex, 1));
                } else {
                    // reordering existing
                    allTableColumns.splice(newIndex, 0, ...allTableColumns.splice(oldIndex, 1));
                }
            });

            // return early if columns didn't change
            if (isEqual(this.allTableColumns, allTableColumns)) {
                return this.$nextTick();
            }

            this.reloadColumns(allTableColumns);

            const visibleColumns = this.tableColumns.reduce((columns, column) => {
                if (column.property) {
                    columns.push(column.property);
                }

                return columns;
            }, []);

            emitEvent(this, 'column-change', [{ columns: visibleColumns }]);

            return this.$nextTick();
        },
        collapseTable () {
            requestAnimationFrame(() => {
                this.collapsed = true;
                this.collapseTooltipContent = 'Expand';
                emitEvent(this, 'collapse-change', [{ collapsed: this.collapsed }]);
            });
        },
        expandTable () {
            requestAnimationFrame(() => {
                this.collapsed = false;
                this.collapseTooltipContent = 'Collapse';
                emitEvent(this, 'collapse-change', [{ collapsed: this.collapsed }]);
            });
        },
        toggleTableCollapse () {
            this.collapseTooltipContent = null;

            if (this.collapsed) {
                return this.expandTable();
            }

            this.collapseTable();
        },
        toggleColumnHeaderHover (column, colIndex) {
            if (!colIndex && colIndex !== 0) {
                this.hoverColumnHeader = null;

                return;
            }

            if (!column.sortable && !column.filters.length) {
                return;
            }

            requestAnimationFrame(() => {
                this.hoverColumnHeader = colIndex;
            });
        },
        toggleRowActive (row) {
            requestAnimationFrame(() => {
                this.activeRow = row[this.rowKey];
            });
        },
        clearRowHover () {
            const hoveredRows = this.$el.querySelectorAll('.pendo-table__row.is-hover');
            if (hoveredRows.length) {
                hoveredRows.forEach((elem) => {
                    removeClass(elem, 'is-hover');
                });
            }

            this.hoverRow = null;
            emitEvent(this, 'row-hover-clear');
        },
        setRowHover (row, index) {
            if (!this.highlightHoverRow || this.scrollStore.scrollActive || this.hoverRow === row[this.rowKey]) {
                return;
            }

            this.clearRowHover();
            addClass(this.$el.querySelector(`.pendo-table__row[data-uid="${index}"]`), 'is-hover');
            this.hoverRow = row[this.rowKey];
            emitEvent(this, 'row-hover-change', { row });
        },
        toggleAllRowsSelect (value) {
            if (value && this.checkboxConfig.selectAll !== false) {
                const selectableRows = this.tableData.filter((row) => {
                    if (row.isGroup) {
                        return false;
                    }

                    if (this.checkboxConfig.checkMethod) {
                        return this.checkboxConfig.checkMethod(row);
                    }

                    return true;
                });
                this.selectedMap = keyBy(selectableRows, this.rowKey);
                emitEvent(this, 'select-all', [{ selectedRows: this.getSelectedRows() }]);
            } else {
                this.clearSelected();
            }

            this.updateSelectionStatus();
            emitEvent(this, 'select-change', [{ selectedRows: this.getSelectedRows() }]);
        },
        toggleRowSelect ({ row }) {
            if (this.selectedMap[row[this.rowKey]]) {
                this.$delete(this.selectedMap, row[this.rowKey]);
                emitEvent(this, 'select-remove', [{ row, checked: false }]);
            } else {
                this.$set(this.selectedMap, row[this.rowKey], row);
                emitEvent(this, 'select-add', [{ row, checked: true }]);
            }

            this.updateSelectionStatus();
            emitEvent(this, 'select-change', [{ row, selectedRows: this.getSelectedRows() }]);
        },
        toggleRowGroupExpand (row) {
            row = this.rowsMap.get(row[this.rowKey]);

            if (this.groupExpandedMap[row[this.rowKey]]) {
                this.$delete(this.groupExpandedMap, row[this.rowKey]);
            } else {
                this.$set(this.groupExpandedMap, row[this.rowKey], row);
            }

            emitEvent(this, 'group-expand-change', [{ row, groupExpandedKeys: Object.keys(this.groupExpandedMap) }]);

            this.updateActiveTableData();

            return this.$nextTick();
        },
        toggleRowTreeExpand (row) {
            row = this.rowsMap.get(row[this.rowKey]);

            if (this.treeExpandedMap[row[this.rowKey]]) {
                this.$delete(this.treeExpandedMap, row[this.rowKey]);
                row[this.treeConfig.children].forEach((childRow) => {
                    this.$delete(this.treeExpandedChildMap, childRow[this.rowKey]);
                });
            } else {
                this.$set(this.treeExpandedMap, row[this.rowKey], row);
                row[this.treeConfig.children].forEach((childRow) => {
                    this.$set(this.treeExpandedChildMap, childRow[this.rowKey], childRow);
                });
            }

            emitEvent(this, 'tree-expand-change', [{ row, treeExpandedRows: this.getTreeExpandedRows() }]);

            this.updateActiveTableData();
            this.updateTreeExpandStatus();

            return this.$nextTick();
        },
        toggleAllRowsTreeExpand () {
            if (this.treeConfig.expandAll === false) {
                return;
            }

            if (!this.isAllTreeExpanded) {
                const treeExpandableRows = this.tableData.filter((row) => row[this.treeConfig.children]);
                this.treeExpandedMap = keyBy(treeExpandableRows, this.rowKey);
                this.treeExpandedChildMap = treeExpandableRows.reduce((acc, row) => {
                    row[this.treeConfig.children].forEach((childRow) => {
                        acc[childRow[this.rowKey]] = childRow;
                    });

                    return acc;
                }, {});
            } else {
                this.clearTreeExpand();
            }

            emitEvent(this, 'tree-expand-change', [{ treeExpandedRows: this.getTreeExpandedRows() }]);

            this.updateActiveTableData();
            this.updateTreeExpandStatus();

            return this.$nextTick();
        },
        updateTreeExpandStatus () {
            const treeExpandedRows = this.getTreeExpandedRows();

            if (!treeExpandedRows.length) {
                this.isAllTreeExpanded = false;

                return;
            }

            const totalTreeExpandableRows = this.tableData.filter((row) => row[this.treeConfig.children]);
            this.isAllTreeExpanded = treeExpandedRows.length === totalTreeExpandableRows.length;
        },
        handleCellClick (event, { cell, column, row }) {
            if (this.highlightCurrentRow) {
                requestAnimationFrame(() => {
                    this.selectRow = row;
                });
            }

            emitEvent(this, 'cell-click', [{ cell, column, event, row }]);
        },
        handleDefaultSort () {
            const { prop, order } = this.tableDefaultSort;
            const sortedColumn = this.tableColumns.find((column) => column.property === prop);
            if (!sortedColumn) {
                return;
            }
            const { property } = sortedColumn;
            let sortOrder = 'none';
            if (['asc', 'ascending'].includes(order)) {
                sortOrder = 'ascending';
            }
            if (['desc', 'descending'].includes(order)) {
                sortOrder = 'descending';
            }
            this.setActiveSort(property, sortOrder);
        },
        setGroupExpandedRows () {
            const isRowGroupingEnabled = get(this.groupConfig, 'enabled', false);
            const expandedGroupKeys = get(this.groupConfig, 'expandedKeys');

            if (isRowGroupingEnabled && expandedGroupKeys) {
                expandedGroupKeys.forEach((key) => {
                    const row = this.rowsMap.get(key);
                    if (row) {
                        this.$set(this.groupExpandedMap, key, row);
                    }
                });
            }

            return this.$nextTick();
        },
        setActiveSort (prop, order) {
            const { tableColumns, sortStore } = this;
            const column = tableColumns.find((col) => col.property === prop);

            if (sortStore.column && column.property !== sortStore.column.property) {
                this.sortStore = {
                    column: null,
                    activeSort: 'none'
                };
            }

            if (!order) {
                order = {
                    none: 'ascending',
                    ascending: 'descending',
                    descending: 'none'
                }[this.sortStore.activeSort || 'none'];
            }

            if (this.sortStore.activeSort !== order) {
                this.sortStore = {
                    column: {
                        ...column,
                        order
                    },
                    activeSort: order
                };

                if (column.sortable !== 'custom') {
                    this.updateActiveTableData();
                }

                emitEvent(this, 'sort-change', [{ column, prop, order }]);
            }

            return this.$nextTick();
        },
        filterColumn ({ column, appliedFilters }) {
            const updatedFilters = this.filterStore.options.map((option) => {
                option.checked = appliedFilters.includes(option.value);

                return option;
            });

            this.filterStore.options = updatedFilters;

            this.updateActiveTableData();

            emitEvent(this, 'filter-change', [{ column, prop: column.property, values: this.filterStore.options }]);
        },
        showFilterPanel (column) {
            const isAllSelected = column.filters.every(({ checked }) => checked);
            const isIndeterminate = !isAllSelected && column.filters.some(({ checked }) => checked);

            this.filterStore = {
                column,
                isAllSelected,
                isIndeterminate,
                multiple: column.filterMultiple,
                options: column.filters,
                visible: true
            };
        },
        hideFilterPanel () {
            this.filterStore = {
                ...this.filterStore,
                isAllSelected: false,
                isIndeterminate: false,
                options: [],
                visible: false
            };

            return this.$nextTick();
        },
        updateTableHeight () {
            const { title, filters, tableHeader } = this.$refs;
            let nonBodyHeight = 0;

            const fixedHeight = parseInt(this.tableHeight, 10);
            const maxHeight = parseInt(this.tableMaxHeight, 10);

            if (title && title.$el) {
                nonBodyHeight += title.$el.offsetHeight;
            }

            if (filters) {
                nonBodyHeight += filters.offsetHeight;
            }

            if (tableHeader && tableHeader.$el) {
                nonBodyHeight += tableHeader.$el.offsetHeight;
            }

            if ((!fixedHeight && !maxHeight) || this.tableAutoHeight) {
                if (!fixedHeight && !maxHeight && !this.tableAutoHeight) {
                    warn(
                        'missing height/maxHeight/autoHeight property. Table height rendering may be negatively impacted.',
                        this
                    );
                }

                const tableScrollParent = scrollParent(this.$el) || document.documentElement;
                const { top } = this.$el.getBoundingClientRect();
                const topRelativeToViewport = top + tableScrollParent.scrollTop;
                const componentHeight = window.innerHeight - topRelativeToViewport - this.tableAutoHeightOffset;
                const bodyHeight = componentHeight - nonBodyHeight;

                if (this.scrollStore.pageMode) {
                    if (!this.status || this.status === 'resolved') {
                        this.bodyStyle = { maxHeight: '100%' };
                        this.tableStyle = { maxHeight: '100%' };
                    } else {
                        this.bodyStyle = { height: `${bodyHeight}px` };
                        this.tableStyle = { height: `${componentHeight}px` };
                    }

                    return this.$nextTick();
                }

                this.bodyStyle = {
                    maxHeight: `${bodyHeight}px`
                };

                this.tableStyle = {
                    maxHeight: `${componentHeight}px`
                };

                return this.$nextTick();
            }

            if (fixedHeight) {
                this.bodyStyle = {
                    height: `${fixedHeight - nonBodyHeight}px`
                };

                this.tableStyle = {
                    height: `${this.collapsed ? nonBodyHeight : fixedHeight}px`
                };

                return this.$nextTick();
            }

            if (maxHeight) {
                this.bodyStyle = {
                    maxHeight: `${maxHeight - nonBodyHeight}px`
                };

                this.tableStyle = {
                    maxHeight: `${maxHeight}px`
                };
            }

            return this.$nextTick();
        },
        addRow (row) {
            if (findRowLocation(row, this)) {
                warn(`row with ${this.rowKey} ${row[this.rowKey]} already exists.`, this);

                return;
            }

            this.rowsMap.set(row[this.rowKey], row);
            this.updateActiveTableData();
        },
        updateRow (row) {
            const rowLocation = findRowLocation(row, this);
            if (!rowLocation) {
                warn(`cannot find row with ${this.rowKey} ${row[this.rowKey]}.`, this);

                return;
            }

            if (rowLocation.rowSet) {
                rowLocation.rowSet[rowLocation.targetRowIndex] = row;
            } else {
                this.rowsMap.set(rowLocation.key, row);
            }

            this.updateActiveTableData();
        },
        updateRows (rows) {
            let hasUpdatedRows = false;

            rows.forEach((row) => {
                const rowLocation = findRowLocation(row, this);
                if (!rowLocation) {
                    warn(`cannot find row with ${this.rowKey} ${row[this.rowKey]}.`, this);

                    return;
                }

                if (rowLocation.rowSet) {
                    rowLocation.rowSet[rowLocation.targetRowIndex] = row;
                } else {
                    this.rowsMap.set(rowLocation.key, row);
                }

                hasUpdatedRows = true;
            });

            if (hasUpdatedRows) {
                this.updateActiveTableData();
            }
        },
        removeRow (row) {
            const rowLocation = findRowLocation(row, this);
            if (!rowLocation) {
                return;
            }

            if (rowLocation.rowSet) {
                rowLocation.rowSet.splice(rowLocation.targetRowIndex, 1);
            } else {
                this.rowsMap.delete(rowLocation.key);
            }

            this.$delete(this.selectedMap, row[this.rowKey]);
            this.updateActiveTableData();
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: block;
    border-left: $table-border;
    border-right: $table-border;
    color: $table-text-color;
    background-color: $table-background-color;
    @include font-base;
    transition-property: height, max-height;
    transition-duration: 150ms;
    will-change: height, max-height;

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 1;
        background-color: $table-border-color;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
        z-index: 1;
    }

    table {
        border-spacing: 0;
        border-collapse: separate;
        font-variant-numeric: tabular-nums;
        table-layout: fixed;
        width: 100%;
    }

    @supports not (font-variant-numeric: tabular-nums) {
        table {
            font-feature-settings: 'tnum' on;
        }
    }

    @include element(title) {
        min-height: 49px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: space-between;
        padding: 0 8px 0 16px;
        font-size: 18px;
        background: $color-white;
        z-index: 1;
        border-bottom: $table-border;
        border-radius: 3px 3px 0 0;
    }

    h3,
    .pendo-table__title-text {
        @include heading-reset;
    }

    @include element(title-info) {
        display: grid;
        grid-gap: 8px;
        grid-auto-flow: column;
        align-items: center;
        line-height: 20px;
    }

    @include element(active-count) {
        font-size: 18px;
        color: $color-text-secondary;
        overflow: hidden;
        font-variant-numeric: tabular-nums;
    }

    @include element(clear-selected) {
        font-size: 14px;
    }

    @include element(title-action) {
        @include modifier(collapse) {
            .pendo-icon {
                transition: transform 0.25s;
                transform: rotate(0);
            }
        }
    }

    @include element(title-actions) {
        display: flex;
        align-items: center;
    }

    @include element(content) {
        position: relative;
    }

    @include element(filters) {
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: $table-border;
        background: $color-white;

        .pendo-tag + .pendo-tag {
            margin-left: 4px;
        }

        > * {
            margin: 4px;
        }
    }

    @include element(header) {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        z-index: 0;
        background-color: $color-gray-10;

        .pendo-table__column {
            background-color: $table-header-background;
            border-bottom: $table-border;
            position: relative;
            height: 38px;

            &.is-hover {
                background-color: $table-header-background-hover;
                .pendo-table__column-sort {
                    .pendo-icon__chevron-up,
                    .pendo-icon__chevron-down {
                        svg {
                            stroke: $color-gray-80;
                        }
                    }
                }
            }

            .pendo-table__cell {
                user-select: none;
                white-space: nowrap;

                @include modifier((ellipsis, title, tooltip, clamp)) {
                    span {
                        @include ellipsis;
                    }
                }
            }

            &.pendo-table__column--sortable {
                cursor: pointer;

                .pendo-table__cell {
                    cursor: pointer;
                }
            }
        }

        @include is(dragging) {
            cursor: col-resize;

            .pendo-table__column {
                pointer-events: none;
            }
        }
    }

    @include element(scroll-sentinal) {
        width: 0;
        height: 38px;
    }

    @include element(resizable) {
        position: absolute;
        right: 0;
        top: 0;
        width: 5px;
        height: 100%;
        z-index: 0;
        user-select: none;
        cursor: col-resize;

        &:hover {
            background-color: $color-teal-70;
        }
    }

    @include element(resize-bar) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            width: 5px;
            left: -2px;
            height: 37px;
            background-color: $color-teal-70;
            cursor: col-resize;
        }

        &:after {
            content: '';
            display: block;
            height: 100%;
            background-color: $color-teal-70;
        }
    }

    @include element(repair) {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        z-index: 1;
        background-color: $table-border-color;
    }

    @include element(body) {
        position: relative;
        overflow-y: auto;
        overscroll-behavior-x: contain;
        background-color: $color-gray-10;
    }

    @include element(scroll-container) {
        will-change: transform;
    }

    @include element(row) {
        contain: content;
        background-color: $table-row-background;

        .pendo-table__column {
            border-bottom: $table-border;
        }

        @include is(expanded) {
            .pendo-table__column {
                border-bottom: thin solid transparent;
            }
        }

        @include is(hover) {
            background-color: $table-row-hover-background;

            .pendo-table__column {
                background-color: $table-row-hover-background;
            }

            .pendo-table__column--actions:before {
                opacity: 1;
            }
        }

        @include is(active) {
            background-color: $table-row-hover-background;

            .pendo-table__column {
                background-color: $table-row-hover-background;
            }

            .pendo-table__column--actions:before {
                opacity: 1;
            }
        }

        @include is(selected) {
            background-color: $table-row-selected-background;

            .pendo-table__column {
                background-color: $table-row-selected-background;
            }
        }

        @include modifier(expand) {
            background-color: $table-row-expand-background;

            @include is(expanded) {
                .pendo-table__column {
                    border-bottom: $table-border;
                    height: 56px;

                    &.is-fixed-left,
                    &.is-fixed-right {
                        background-color: $table-row-expand-background;
                    }
                }
            }
        }

        @include modifier(group) {
            background-color: $table-row-expand-background;
            .pendo-table__column {
                &.is-fixed-left,
                &.is-fixed-right {
                    background-color: $table-row-expand-background;
                }
            }
        }

        @include modifier(expandable-group) {
            .pendo-table__cell {
                display: flex;
                align-items: center;
                padding-left: 0px;
            }
        }
    }

    @include element(column) {
        text-align: left;
        height: 56px;

        &:before,
        &:after {
            width: 39px;
        }

        @include modifier(center) {
            text-align: center;
        }

        @include modifier(right) {
            text-align: right;
        }

        @include modifier(actions) {
            z-index: 1;
            right: 0;

            &:before {
                content: '';
                opacity: 0;
                position: absolute;
                width: 56px;
                height: 100%;
                top: 0;
                right: 100%;
                z-index: 1;
                pointer-events: none;
                background: linear-gradient(270deg, $color-gray-10 0%, rgba(248, 248, 249, 0) 100%);
            }

            .pendo-table__cell {
                display: grid;
                grid-auto-flow: column;
                grid-auto-columns: auto;
                align-items: center;
                grid-gap: 16px;
                justify-content: end;
            }
        }

        @include modifier((fixed-left, fixed-right)) {
            z-index: 1;
            position: sticky !important;
            background-color: $table-row-background;
        }

        @include modifier(fixed-shadow) {
            &:before,
            &:after {
                opacity: 0;
                position: absolute;
                width: 56px;
                height: 100%;
                top: 0;
                z-index: 1;
                pointer-events: none;
            }

            &.pendo-table__column--fixed-left:after {
                content: '';
                background: linear-gradient(to right, rgba(0, 0, 0, 0.05) -10%, rgba(0, 0, 0, 0) 12.5%);
            }

            &.pendo-table__column--fixed-right:before {
                content: '';
                background: linear-gradient(to left, rgba(0, 0, 0, 0.05) -10%, rgba(0, 0, 0, 0) 12.5%);
            }
        }

        @include modifier(fixed-left) {
            left: 0;
        }

        @include modifier(fixed-right) {
            right: 0;
        }

        @include is(fixed-left) {
            &.pendo-table__column--fixed-shadow {
                &:after {
                    opacity: 1;
                    left: 100%;
                }
            }
        }

        @include is(fixed-right) {
            &.pendo-table__column--fixed-shadow {
                &:before {
                    opacity: 1;
                    right: 100%;
                }
            }
        }

        @include is(fixed-actions) {
            position: sticky !important;
        }

        @include modifier((filterable, sortable)) {
            cursor: pointer;

            .pendo-table__cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
            }
        }
    }

    @include element(column-actions) {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    @include element(column-sort) {
        display: grid;
        grid-template-rows: 12px 12px;
        justify-content: center;
        width: 16px;

        &.is-ascending,
        &.is-descending {
            grid-template-rows: 1fr;
        }

        .pendo-icon {
            display: grid;
            justify-content: center;
            align-items: center;

            & {
                margin-left: 0;
            }
        }
    }

    @include element(column-filters) {
        height: 24px;
    }

    @include element(column-filters-trigger) {
        @include button-reset;
        height: 24px;
        cursor: pointer;

        &:hover {
            .pendo-icon__filter {
                svg {
                    stroke: $color-gray-80;
                }
            }
        }
    }

    @include element(column-filters-panel-header) {
        .pendo-button {
            margin: 4px 16px 0px 16px;
        }
    }

    @include element(column-filters-panel-input) {
        padding: 8px 16px;
        border-bottom: 1px solid $color-gray-40;

        .pendo-multiselect__control {
            border: 1px solid $color-gray-100;
            border-radius: 3px;
            height: 36px;
            padding: 0 8px;
        }

        .pendo-multiselect__input-wrapper {
            flex: unset;
        }
    }

    @include element(group-expand) {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-gray-10;
        width: 48px;

        .pendo-icon-button {
            padding: 0px 4px;
        }

        .pendo-icon {
            transition: transform 0.25s;
            transform: rotate(0);
        }

        @include is(expanded) {
            .pendo-icon {
                transform: rotate(-180deg);
            }
        }
    }

    .pendo-loading {
        &.pendo-loading-overlay {
            grid-gap: 32px !important;
            z-index: 1;
            top: 1px;
        }

        &.pendo-loading--text {
            .pendo-loading-spinner {
                align-items: end;
            }
        }
    }

    @include element(empty-block) {
        height: 100%;
        min-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @include element(empty-text) {
        display: block;
        width: 50%;
    }

    @include element(status-container) {
        height: 100%;
        width: 100%;
        top: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;

        .pendo-status-overlay > * {
            pointer-events: auto;
        }
    }

    @include modifier(striped) {
        @include element(row) {
            &:nth-child(2n) {
                background-color: $table-row-stripe-background;
            }
        }
    }

    @include modifier(border) {
        .pendo-table__column {
            border-right: $table-border;

            &:last-child {
                border-right-color: $color-white;
            }
        }

        &.pendo-table--scrollable-y {
            .pendo-table__column:last-child {
                border-right: $table-border;
            }
        }
    }

    @include modifier(condensed) {
        .pendo-table__body {
            .pendo-table__column {
                height: 40px;
            }
        }

        .pendo-table-column--fixed-left,
        .pendo-table-column--fixed-right {
            &:before,
            &:after {
                width: 40px;
            }
        }

        .pendo-table__column--actions {
            &::before {
                height: 38px;
                width: 40px;
            }
        }
    }

    @include modifier(borderless) {
        border: 0;

        &:before,
        &:after {
            height: 0px;
        }

        .pendo-table__row:last-child {
            .pendo-table__column {
                border-bottom: 0;
            }
        }

        .pendo-table__header {
            border-top: none;
        }
    }

    @include modifier((filters, title)) {
        border-radius: 3px 3px 0 0;

        &:before {
            height: 0px;
        }
    }

    @include modifier(title) {
        border-top: $table-border;

        .pendo-loading {
            &.pendo-loading-overlay {
                top: 0px;
            }
        }
    }

    @include modifier(draggable) {
        .pendo-table__body {
            tbody {
                user-select: none;
            }
        }
    }

    @include is(collapsed) {
        @include element(title) {
            min-height: 48px;
            border-bottom-width: 0px;
            border-radius: 3px;
            transition-delay: 200ms;
            transition-property: border-bottom, border-radius, min-height;
            transition-duration: 10ms;
        }

        @include element(title-action) {
            @include modifier(collapse) {
                .pendo-icon {
                    transform: rotate(-180deg);
                }
            }
        }

        border-radius: 3px;
        border: $table-border;

        &:after {
            display: none;
        }
    }

    @include element(cell) {
        cursor: default;
        user-select: auto;
        white-space: normal;
        word-break: break-all;
        padding-left: 16px;
        padding-right: 16px;
        line-height: 22px;

        @include modifier((ellipsis, title, tooltip, clamp)) {
            @include ellipsis;
        }

        @include modifier(checkbox) {
            display: flex;

            > span {
                display: flex;
            }
        }

        @include modifier(clamp) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: unset;
            color: $color-text-secondary;
            font-size: 12px;
            line-height: 18px;
        }

        @include modifier(expand) {
            cursor: pointer;
        }

        @include modifier(index) {
            padding: 0;
            text-align: center;
            will-change: contents;
        }

        @include modifier(text) {
            will-change: contents;
        }

        @include modifier(tree) {
            line-height: unset;
            height: 100%;
            padding: 2px;
        }

        a {
            cursor: pointer;
            color: $color-link;
            font-weight: 600;

            &:hover {
                color: $color-link-hover;
                text-decoration: underline;
            }
        }

        .pendo-tag {
            transition: none;
        }
    }

    @include modifier(page-mode-scroll) {
        overflow: unset;

        @include element((title, header, filters)) {
            position: sticky;
            z-index: 1;
        }

        @include element(status-container) {
            z-index: 2;
        }

        &:after {
            z-index: 2;
        }
    }

    @include is(underflowing) {
        @include element(column) {
            &:last-child {
                border-right: $table-border;
            }
        }
    }
}
</style>
