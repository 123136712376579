<template>
    <div
        class="pendo-multiselect-option--group"
        :class="{
            'pendo-multiselect-option--group-icon': !!option.icon
        }"
        v-bind="$attrs"
        v-on="$listeners">
        <svg
            v-pendo-tooltip="{
                content: group,
                delay: { show: 300, hide: 0 },
                multiline: true
            }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="12"
            height="12">
            <rect
                width="12"
                height="12"
                x="0"
                y="0"
                :fill="color"
                rx="2" />
        </svg>
        <pendo-icon
            v-if="option.icon"
            v-bind="icon" />
        <span
            ref="label"
            v-pendo-tooltip="{
                content: label,
                multiline: true,
                html: false,
                trigger: 'manual',
                offset: 0,
                show: showOverflowTooltip
            }"
            class="pendo-multiselect-option__label"
            @mouseenter="onOptionMouseenter"
            @mouseleave="onOptionMouseleave">
            {{ label }}
        </span>
    </div>
</template>

<script>
import { shouldAttachOverflowTooltip } from '@/components/multiselect/multiselect-helper';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';
import { PRODUCT_AREA_COLORS } from '@/utils/color';

export default {
    name: 'PendoGroupOption',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        }
    },
    data () {
        return {
            showOverflowTooltip: false,
            tooltipTimeout: null
        };
    },
    computed: {
        label () {
            return this.multiselect.getOptionLabel(this.option);
        },
        group () {
            let content = 'No Product Area';

            if (this.option.group && this.option.group.name) {
                content = this.option.group.name;
            }

            return content;
        },
        color () {
            if (this.option.group && this.option.group.color) {
                let { color } = this.option.group;
                if (color.includes('.')) {
                    color = color.substr(1);
                }

                return PRODUCT_AREA_COLORS[color];
            }

            return PRODUCT_AREA_COLORS.groupColorUngrouped;
        },
        icon () {
            return {
                size: 14,
                ...this.option.icon
            };
        }
    },
    methods: {
        onOptionMouseenter () {
            const showOverflowTooltip = shouldAttachOverflowTooltip(this.label, this.$refs.label);

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.showOverflowTooltip = true;
                }, 300);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.showOverflowTooltip = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(group) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        height: 100%;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 12px 1fr;
        align-items: center;
        overflow: hidden;

        @include element(label) {
            @include ellipsis;
        }
    }

    @include modifier(group-icon) {
        grid-template-columns: 12px 14px 1fr;
    }
}
</style>
